/*********************************************
:: Buttons                             
********************************************/

button {
    outline: none;
}

/*******************************************/
// Default Button

button.button,
input.button,
a.button {
    display: inline-block;
    min-width: 155px;
    height: 55px;
    padding: 0px 38px;

    font-family: $font-family-2;
    font-size: 16px;
    font-weight: $font-semibold;
    line-height: 53px;
    text-decoration: none;
    text-align: center;
    color: white;

    background: $color-1;
    border: 1px solid $color-1;
    border-radius: 2px;

    transition: all .4s ease-in-out;

    &:hover {
		background: transparent;
        color: #CCCCCC;
    }

    &.dark {
        background: $color-2;
        border-color: $color-2;

        &:hover {
		    background: transparent;
            color: #CCCCCC;
        }
    }

    &.border {
        background: transparent;
        border-color: white;
    }

    &.small {
        height: 46px;
        line-height: 44px;
    }
}

/*******************************************/
// Disabled Button Styles

.button-disabled,
.button[disabled],
button[disabled],
button[type="submit"][disabled],
input[type="submit"][disabled],
input[type="button"][disabled],
input[type="reset"][disabled]{
	color:white;
	opacity: 0.5;
	cursor: default;
}

