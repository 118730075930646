/*********************************************
:: Logos Module                    
********************************************/
.logos-container {
	width: 100%;
    padding: 50px 0 80px;

    text-align: center;

    background: $color-4;

    p {
        margin-bottom: 26px;

        font-size: 18px;
        font-weight: $font-semibold;
        letter-spacing: -0.3px;
        color: #2c2c2c;
    }

    article {
        display: flex !important;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 147px;
        margin: 0 5px;
        padding: 0 5px;

        border: 1px solid #dcdfe6;

        @include mappy-bp(medium){ padding: 0 30px; }
        @include mappy-bp(mediumish){ margin: 0 15px; }

        img {
            // min-width: 50%;
            margin: 30px;

            @include mappy-bp(mediumish){
                margin: 0 auto;
	        }
        }
    }

}
/********************************************/