/*********************************************
:: Forms
********************************************/

form {
}

legend {
    margin-bottom: 15px;
    @extend h2;
}

label {
    font-size: 12px;
    line-height: 20px;
    font-weight: $font-light;
    color: black;
}

/*******************************************/
// Disabled Field Styles
.field-disabled,
input[disabled],
textarea[disabled],
select[disabled],
input[readonly],
textarea[readonly],
select[readonly] {
    opacity: 0.5;
    cursor: default;
}

/*******************************************/
// Core Field Styles
input, textarea, select {
    display: inline-block;
    width: 100%;
    height: 52px;
    padding: 6px 13px;

    font-size: 14px;
    line-height: 26px;
    color: $color-2;
    
    background: white;
    border: 1px solid #dcdfe6;
    border-radius: 4px;

    outline: none;
    -webkit-appearance: none;
    transition: all .5s ease-in-out;

    &:hover, &:focus {
        border: 1px solid #909399;
    }

}

/*******************************************/
// Misc Field Styles
*::placeholder {
    font-family: $font-family-2;
    font-size: 14px;
    font-weight: 500;
    color: #9b9b9b;
}

textarea, select[multiple] {
    height: 160px;
}

input[type=checkbox], input[type=radio] {
    height: auto;
}

/*******************************************/
// Custom Checkbox/Radio Styles (no JS)
.form-field .custom-checkbox-radio {
    float: left;
    width: auto;
    margin: 0;
    padding: 0 0 5px;

    font-weight: 400;
    line-height: 24px;
    cursor: pointer;

    input {
      display: inline-block;
      vertical-align: top;
      width: 15px;
      height: 15px;
      margin: 2px 5px 0 0;
      padding: 0;

      background: none;
      border: none;
      cursor: pointer;
    }

}

/*******************************************/
// Form Field Container
.form-field {
    float:left;
    width: 100%;
    padding: 0 0 16px;

    label{
        width: 100%;
        margin-bottom: 3px;
    }
}

/*******************************************/
// Button Field Container
.button-field {
    float:left;
    width: 100%;
    margin-bottom: 15px;
    padding: 0 0 15px;

    text-align: center;

    button, input[type="submit"], input[type="reset"] {
        display: block;
        width: 196px;
        height: 55px;
        margin: 0 auto;

        font-size: 16px;
        font-weight: 700;
        letter-spacing: -0.3px;
        color: white;

        background: $color-1;
        border-radius: 2px;
        transition: all .4s ease-in-out;

        @include mappy-bp(mediumish){
            float:left;
            margin: -3px 0 0 0;
        }

        &:hover {
            background: $color-2;
        }
    }
}

/*******************************************/
// Form Fields Stack

.form-fields-stack {
    lost-utility: clearfix;

    &.two-column {
        .form-field {
            width: 100%;
            @include mappy-bp(mediumish){
                lost-column: 1/2 2 10px;
            }
        }
    }

    &.three-column {
        .form-field {
            width: 100%;
            @include mappy-bp(mediumish){
                lost-column: 1/3 3 10px;
            }
        }
    }
}

/*******************************************/
// Form Feedback
.form-field.error input,
.form-field.error textarea,
.form-field.error select,
.form-field.error .SumoSelect>.CaptionCont {
    border-color: $color-attention;
}

.form-field.valid input,
.form-field.valid textarea,
.form-field.valid select,
.form-field.valid .SumoSelect>.CaptionCont{
    color: black;
    border-color: #CCCCCC;
}

label.error, label.error-SumoSelect-label, .php-error li {
    margin: 10px 0 0;
    font-weight: $font-bold;
    color: $color-attention !important;
}

.form-note {
    display: inline-block;
    margin-bottom: 20px;
    font-weight: $font-normal;
    line-height: 1;
}
