/*********************************************
:: Amazing IT Page                   
********************************************/
.page-amazing {

    .services-container {
        padding: 60px 0;

        text-align: center;

        @include mappy-bp(mediumish){
            padding: 80px 0 50px;
        }

        .content-max-width {
            @include mappy-bp(mediumish){
                max-width: 1200px;
            }
        }

        article {
            position: relative;

            width: 100%;
            min-height: 230px;
            margin-bottom: 20px;
            padding: 30px;

            text-align: left;

            border-radius: 2px;
            border: 1px solid #dcdfe6;

            transition: all 0.3s ease-in-out;

            @include mappy-bp(medium){
                width: calc(50% - 30px);
                margin: 0 15px 30px;
                padding: 40px;
            }
            @include mappy-bp(mediumish){
                width: calc(33.33% - 30px);
            }

            h3 {
                margin-bottom: 15px;
            }
        }

        article:hover {
            border-color: $color-1;
            box-shadow: 0 0 10px rgba(110, 204, 28, 0.12);
        }

        article.black {
            background: black;

            h3 { color: white; }

            .button {
                position: absolute;
                bottom: 40px;
                left: 40px;
            }
        }
    }
    /********************************************/
}
