/*********************************************
:: Support Page                   
********************************************/
.page-support {

    .support-container {
        padding: 60px 0;

        text-align: center;

        background: #f6f6f6;;

        @include mappy-bp(mediumish){
            padding: 100px 0 82px;
        }

        a {
            display: block;
        }

        a.button {
            width: 261px;
            margin: 0 auto 18px;
            padding: 0;
        }

        a.portal {
            width: 370px;
            height: 66px;
            margin: 0 auto 18px;
            padding: 13px 20px;

            color: #2c2c2c;
            font-size: 18px;
            font-weight: $font-medium;
            line-height: 38px;
            text-align: left;

            border-radius: 2px;
            border: 1px solid #dcdfe6;
            background: white;

            img {
                display: inline-block;
                vertical-align: middle;
                margin: 0 12px 0 0;
            }
        }

        a.portal:hover {
            border-color: $color-1;
            box-shadow: 0 0 10px rgba(110, 204, 28, 0.12);
        }
    }
    /********************************************/
}
