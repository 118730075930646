/*********************************************
:: Blog Page                   
********************************************/
.page-blog {

    .blog-container {
        padding: 60px 0;

        text-align: center;

        @include mappy-bp(mediumish){
            padding: 80px 0;
        }

        .top {
            width: 100%;
            margin: 0 0 30px;
            text-align: left;

            @include mappy-bp(mediumish){
                max-width: 1170px;
                margin: 0 auto 30px;
            }

            .controls {
                width: 100%;
                padding: 0 0 20px;

                text-align: left;

                border-bottom: 2px solid #2c2c2c;

                button {
                    margin: 0 40px 0 0;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 23px;
                    color: $color-1;
                    cursor: pointer;

                    span {
                        display: inline-block;
                        vertical-align: middle;
                        color: #2c2c2c;
                    }
                }

                button.active {
                    span {
                        transform: rotate(180deg);
                    }
                }
            }

            a, p {
                display: inline-block;
                margin: 0;
                padding: 10px 10px 10px 0;
                font-size: 14px;
                color: $color-2;
                cursor: pointer;
            }

            .categories {
                display: none;
                margin: 0;

                &.active { display: block; }
            }

            .archives {
                display: none;
                margin: 0;

                &.active { display: block; }

                .year {
                    position: relative;

                    display: inline-block;
                    width: auto;

                    .month {
                        position: absolute;
                        top: 40px;
                        left: 0;
                        z-index: 2;

                        display: none;
                        width: 150px;

                        background: white;
                        border: 1px solid #CCC;

                        a {
                            width: 100%;
                            padding: 10px;
                            border-bottom: 1px solid #ECECEC;
                        }
                    }
                }
                .year:hover {
                    .month { display: block; }
                }
            }
        }

        article.blog-detail {
            display: flex;
            width: 100%;
            margin: 0 0 30px;
            padding: 0 0 30px;

            font-size: 0;

            border-bottom: 2px solid #dcdfe6;

            a {
                width: 100%;

                @include mappy-bp(mediumish){
                    display: flex;
                }
            }

            .content {
                width: 100%;

                text-align: left;
                
                @include mappy-bp(medium){
                    display: inline-block;
                    vertical-align: top;
                    width: calc(100% - 200px);
                    padding-right: 40px;
                }
                @include mappy-bp(mediumish){ width: calc(100% - 270px); }

                h3 {
                    max-width: 570px;
                    margin-bottom: 15px;

                    font-size: 24px;
                    font-weight: $font-bold;
                    letter-spacing: -0.2px;
                    color: #2c2c2c;
                }

                p {
                    margin-bottom: 25px;

                    font-size: 12px;
                }
            }

            .image {
                display: inline-block;
                position: relative;
                width: 270px;

                @include mappy-bp(medium mediumish){ width: 200px; }

                img {
                    width: 100%;
                    margin: 0;
                }
            }
        }

        .button {
            margin-top: 10px;
        }
    }
    /********************************************/
}
/********************************************/

.page-blog.case {

    .blog-container {
        padding: 60px 0;

        text-align: center;

        @include mappy-bp(mediumish){
            padding: 80px 0;
        }

        .content-max-width {
            max-width: 1200px;

            &.flex {
                border-bottom: 2px solid #dcdfe6;
                margin-bottom: 30px;

                @include mappy-bp(mediumish){ justify-content: flex-start; }
            } 
        }

        article.case-study {
            display: inline-block;
            width: 100%;
            max-width: 570px;
            margin: 0 auto 30px;
            padding: 0;
            
            @include mappy-bp(mediumish){
                width: calc(50% - 30px);
                margin: 0 15px;
            }

            .image {
                position: relative;
                width: 100%;

                &:before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    z-index: 2;

                    width: 100%;
                    height: 130px;
                    
                    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
                }

                img { width: 100%; margin: 0; }
            }

            .content {
                position: relative;
                z-index: 9;

                width: calc(100% - 70px);
                margin: -65px 0 0;
                padding: 30px;

                text-align: left;

                background: white;

                h3 {
                    margin-bottom: 6px;

                    font-size: 24px;
                    font-weight: $font-bold;
                    letter-spacing: -0.2px;
                    color: #2c2c2c;
                }

                h4 {
                    margin-bottom: 15px;
                    
                    font-size: 18px;
                    font-weight: $font-normal;
                    letter-spacing: -0.2px;
                    color: $color-1;
                }

                p {
                    margin-bottom: 25px;
                }

                span.green {
                    font-weight: 700;
                    color: $color-1;
                }
            }
        }

    }
}
/********************************************/
