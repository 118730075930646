/*********************************************
:: Process Module                    
********************************************/
.process-module {
    padding: 60px 0 20px;
    text-align: center;

    @include mappy-bp(mediumish){
        padding: 80px 0 40px;
    }

    .content-max-width {
        @include mappy-bp(largeish){
            max-width: 1210px;
        }
    }

    article {
        max-width: 670px;

        text-align: center;

        @include mappy-bp(medium){
            width: 50%;
            padding-right: 20px;
        }
        @include mappy-bp(mediumish){
            width: 25%;
            text-align: left;
        }

        img {
            width: 75px;
            margin: 0 auto 20px;

            @include mappy-bp(medium){ margin: 0 0 20px; }
        }

        h4 {
            margin-bottom: 15px;

            font-size: 24px;
            font-weight: $font-bold;
        }
    }
}
/********************************************/