/*********************************************
:: Service Detail Page
********************************************/
.page-service-detail {

    .service-container {
        padding: 60px 0;

        text-align: center;

        @include mappy-bp(mediumish){
            padding: 80px 0 50px;
        }

        .content-max-width {
            @include mappy-bp(mediumish){
                max-width: 1200px;
            }
        }

        a.article {
            position: relative;

            display: flex;
            justify-content: center;
            align-items: center;

            width: 100%;
            min-height: 152px;
            margin-bottom: 20px;
            padding: 0 30px;

            text-align: center;

            border-radius: 2px;
            border: 1px solid #dcdfe6;

            transition: all 0.3s ease-in-out;

            @include mappy-bp(medium){
                width: calc(50% - 30px);
                margin: 0 15px 30px;
            }
            @include mappy-bp(mediumish){
                width: calc(33.33% - 30px);
            }

            p {
                margin: 0;

                font-size: 18px;
                font-weight: 600;
                line-height: 24px;
                color: #2c2c2c;
            }
        }

        a.article:hover {
            border-color: $color-1;
            box-shadow: 0 0 10px rgba(110, 204, 28, 0.12);
        }
    }
    /********************************************/
}
