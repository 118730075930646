/*********************************************
:: Articles CTA Module                    
********************************************/
.articles-cta-module {
    position: relative;
    padding: 60px 0;

    @include mappy-bp(mediumish){
        padding: 80px 0;
    }

    .content-max-width { max-width: 1200px; }

    article {
        width: 100%;
        padding: 30px;

        text-align: left;

        border-radius: 2px;
        box-shadow: 0 0 12px rgba(0, 0, 0, 0.11);

        @include mappy-bp(mediumish){
            width: calc(50% - 30px);
            margin: 0 15px;
            padding: 40px;
        }

        h4 {
            margin-bottom: 15px;

            font-size: 24px;
            line-height: 29px;
            letter-spacing: -0.3px;

            @include mappy-bp(mediumish){
                font-size: 40px;
                line-height: 50px;
            }
        }

        p {
            margin-bottom: 44px;

            font-size: 18px;
            font-weight: 500;
            line-height: 28px;
        }
    }

    article.black {
        background-color: #1b1b1b;

        h4, p { color: white; }
    }
}
/********************************************/