/*********************************************
:: Title Module                    
********************************************/
.title-module{
	width: 100%;
	display: inline-block;
	padding-bottom: 50px;
	@include mappy-bp(mediumish){
		padding-bottom: 30px;
	}

	h1 {
		font-size: 18px;
		font-weight: $font-bold;
		text-align: center;
		text-transform: uppercase;
		line-height: 21px;
		color: black;
		padding: 40px 0 0;
		@include mappy-bp(mediumish){
			font-size: 30px;
			line-height: 36px;
			padding: 20px 0 0;
		}

		&:after {
			content: '';
			height: 3px;
			width: 60px;
			background: $color-1;
			display: block;
			margin: 15px auto;
		}
	}

}
/********************************************/