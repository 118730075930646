/*********************************************
:: Variables                      
********************************************/

//Mappy Breakpoints: https://github.com/zellwk/mappy-breakpoints
    
$breakpoints: (
    tiny: 350px,
    small: 450px,
    smallish: 600px,
    medium: 768px,
    mediumish: 1024px,
    large: 1160px,
    largeish: 1280px,
    huge: 1440px, 
    bigmac: 1920px
);

//Breakpoint Sizes
$mediumish : 1024px;
$tablet : 600px;
$iphone5: 350px;

/*******************************************/
//Fonts

// Base Fonts
$font-family-1: montserrat, 'Montserrat', "Arial", "Helvetica Neue", "Helvetica", sans-serif;
$font-family-2: montserrat, 'Montserrat', "Arial", "Helvetica Neue", "Helvetica", sans-serif;
$font-icon: "icomoon";

// Fonts Weights
$font-light: 300;
$font-normal: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;
$font-extrabold: 900;

/*******************************************/
//Colors

// Brand Colorss
$color-1: #6ecc1c; //green
$color-2: #2c2c2c; //dark
$color-3: #565656; //grey
$color-4: #f6f6f6; // background grey

// Shades of Grey
$color-grey: #979797;
$color-medium-grey: #ccc;
$color-dark-grey: #212121;
$color-light-grey: #ececec;

// Links
$color-link: $color-1;
$color-link-hover: darken($color-link, 12%);

// User Feedback
$color-message: $color-medium-grey;
$color-attention: $color-1;
$color-success: #38B449;

// Social Media
$color-facebook: #3b5998;
$color-twitter: #55acee;
$color-vimeo: #1ab7ea;
$color-youtube: #cd201f;
$color-gplus: #DC4E41;

/*******************************************/
//Page Height Calculator
$header-height-desktop: 82px;
$header-height-mobile: 66px;

$footer-height-desktop: 375px;
$footer-height-mobile: 475px;

//*******************************************/
//Placeholder image

$placeholder-image: "https://www.scandichotels.com/Static/img/placeholders/image-placeholder_3x2.svg";