/*********************************************
  :: Site Footer
********************************************/
.site-footer {
    position: relative;
    width: 100%;
    min-height: $footer-height-mobile;
    padding: 40px 0 40px;

    text-align: center;

    background: black;
    
    @include mappy-bp(mediumish){
        min-height: $footer-height-desktop;
        padding: 82px 0 40px;
    }

    .contact {
        h3 {
            margin-bottom: 19px;
            letter-spacing: 0;
            color: white;
        }

        .numbers {
            a {
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                color: white;
            }

            span {
                display: inline-block;
                vertical-align: middle;
                width: 1px;
                height: 14px;
                margin: 0 10px;

                background: white;
            }
        }

        a.email {
            display: inline-block;
            margin: 5px 0 0;
            font-size: 14px;
            color: $color-1;
        }
    }

    nav.nav {
        padding: 20px 0 20px;

        @include mappy-bp(medium){
            padding: 20px 0 60px;
        }

        li {
            display: inline-block;
            width: 100%;
            margin: 0;
            padding: 0 22px;

            @include mappy-bp(medium){ width: auto; }

            a {
                font-size: 14px;
                font-weight: $font-bold;
                color: white;
            }
        }
    }

    .footer-legal {
        p, a {
            display: inline-block;
            width: 100%;
            margin: 0;

            font-size: 12px;
            line-height: 20px;
            text-align: center;
            color: rgba(#bebebe, 0.5);
        }

        a.term { text-decoration: underline; }
    }
}
