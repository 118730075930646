/*********************************************
  :: Main Mob Menu                             
********************************************/
body.open-menu {
    #mobile-menu {
        right: 0;
    }
}

#mobile-menu {
    position: fixed;
    top: 0;
    bottom: 0; 
    right: -100%;

    width: 100%;

    background: rgba(black, 0.9);
    z-index: 999;
    transition: all .25s;
    -webkit-backface-visibility: hidden;

    @include mappy-bp(mediumish){
        display:none;
    }

    .menu-close-icon {
        position: absolute;
        top: 30px;
        right: 3%;

        width: 50px;
        height: 50px;

        border: none;
        outline: none;

        span {
            font-size: 22px;
            line-height: 50px;
            text-align: center;
            color: white;
        }
    }

    .menu-content {
        padding: 80px 6%;
        overflow-y: auto;

        text-align: center;

        &::-webkit-scrollbar { 
            display: none; 
        }
    }

    a {
        font-family: $font-family-1;
        font-size: 24px;
        font-weight: 700;
        color: white;
        line-height: 28px;
    }

    ul {
        padding: 0 0 40px;
    }

    li {
        margin: 0;
        padding: 6px 0;
    }

    a.button {
        width: 100%;
        margin-bottom: 10px;
        
        font-size: 16px;
        line-height: 44px;
    }

    a.phone {
        display: inline-block;
        margin: 20px 0 0;

        font-size: 16px;
        line-height: 20px;
        color: $color-1;
    }
}