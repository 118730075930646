/*********************************************
  :: Site Header
********************************************/
.site-header {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 99;

	width: 100%;
	height: $header-height-mobile;

	@include mappy-bp(mediumish){
		height: $header-height-desktop;
	}

	.logo {
		position: absolute;
		top: 28px;
		left: 3%;

		width: 160px;
		height: 38px;
		transition: none;

		@include mappy-bp(mediumish){ top: 43px; }
		@include mappy-bp(large){ left: 0; }

		img {
			width: 160px;
			height: 38px;
		}
	}

	nav {
		position: absolute;
		top: 21px;
		right: 3%;

		text-align: right;
		@include mappy-bp(largeish){ right: 0; }
		@include mappy-bp(max-width mediumish){
			display: none;
		}

		li { 
			display: inline-block;
			margin: 0;

			a {
				display: inline-block;

				line-height: 20px;
				color: white;

				&:hover { opacity: 0.7; }
				&.selected {
					position: relative;

					&:before {
						content: '';
						position: absolute;
						top: 22px;
						left: 0;

						width: 100%;
						height: 2px;

						background: $color-1;
					}

					&:hover { opacity: 1; }
					&:active { opacity: 1; }
				}
			}
		}

		ul.top {
			margin-bottom: 15px;

			li a {
				padding: 0 12px;

				font-size: 14px;
				font-weight: $font-normal;
				border-left: 1px solid rgba(#f6f6f6, 0.5);

				&.selected:before { display: none; }
			}

			li:first-of-type a { border: none; }

			li a.phone {
				padding-right: 0;

				font-size: 16px;
				font-weight: $font-bold;
				color: $color-1;
			}
		}
	

		ul.bottom {
			li a {
				margin-left: 24px;

				font-size: 16px;
				font-weight: $font-semibold;
			}
		}
	}

	.hamburger {
        position: absolute;
        top: 35px; 
		right: 3%;

		width: 24px;
    	height: 25px;

		span {
			font-size: 17px;
			color: white;
		}

        &:focus {
            outline: none;
        }
    }
}

/********************************************/