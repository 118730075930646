/*********************************************
:: Blog Page                   
********************************************/
.page-blog-detail {

    .blog-container {
        position: relative;
        padding: 60px 0;

        text-align: left;

        @include mappy-bp(mediumish){
            padding: 83px 0 43px;
        }

        .content-max-width {
            max-width: 570px;
        }

        .wysiwyg {
            text-align: left;
        }

        .social {
            width: 92px;
            height: 263px;
            margin: 0 auto;
            padding: 14px 16px 4px;

            text-align: center;

            border-radius: 2px;
            border: 1px solid #dcdfe6;
            background-color: #ffffff;

            @include mappy-bp(medium){
                position: absolute;
                top: 80px;
                right: 9.4%;
            }

            p {
                margin-bottom: 9px;
                font-size: 12px;
                line-height: 17px;
                color: #565656;
            }

            a {
                display: inline-block;
                width: 32px;
                height: 32px;
                margin: 0 auto 10px;

                line-height: 32px;
                color: white;

                background: #565656;

                span {
                    display: inline-block;
                    font-size: 22px;
                    line-height: 32px;
                }

                &.fb { background: #3b5998; }
                &.linkedin { background: #007fb1; }
                &.twitter { background: #39a9e0; }

                &.copy {
                    width: 60px;
                    margin: 5px auto 10px;
                    background: white;
                }
            }

            input {
                position: absolute;
                z-index: -2;

                display: block;
                width: 20px;
                padding: 0;
                margin: 0;
            }
        }
    }
    /********************************************/

    .pagination-container {
        width: 100%;

        .content-max-width {
            padding: 40px 6%;
            border-top: 1px solid #dcdfe6;

            @include mappy-bp(mediumish){
                padding: 41px 0 79px 0;
            }
        }

        a {
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: -0.7px;
            color: $color-2;

            span {
                margin: 0 5px;
                font-weight: 700;
                line-height: 20px;
                color: $color-1;
            }
        }

        a.prev {
            float: left;
        }

        a.next {
            float: right;
        }

        a.grey {
            color: #d8d8d8;
            span {
                color: #d8d8d8;
            }
        }
    }
    /********************************************/
}
/********************************************/

.page-blog-detail.case {

    .top-centered-module {
        @include mappy-bp(mediumish){
            padding: 322px 0 108px;
        }
    }
    /********************************************/

    .scroll-to {
        display: inline-block;
        width: 100%;

        text-align: center;

        border-bottom: 1px solid #dcdfe6;
        
        a {
            display: inline-block;
            padding: 20px 8px;

            font-size: 18px;
            font-weight: 500;
            line-height: 23px;
            letter-spacing: -0.3px;
            color: $color-2;

            @include mappy-bp(medium){
                padding: 20px 18px;
            }
            @include mappy-bp(mediumish){
                padding: 20px 38px;
            }
        }

        a.active {
            color: $color-1;
        }
    }
    /********************************************/
}
/********************************************/
