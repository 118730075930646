/*********************************************
:: Banner Module                    
********************************************/
.banner-module {
	width: 100%;
    font-size: 0;

    img {
        width: 100%;
        margin: 0;
    }
}
/********************************************/