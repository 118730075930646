/*********************************************
:: Top Content Module                    
********************************************/
.top-centered-module {
	width: 100%;
    padding: 196px 0 35px;

    text-align: center;

    background: #1b1b1b;

    @include mappy-bp(mediumish){
        padding: 322px 0 77px;

        text-align: left;
    }

    .col {
        position: relative;
        width: 100%;
        margin: 0;

        @include mappy-bp(mediumish){
            width: 300px;
        }
    }

    .col:last-of-type {
        @include mappy-bp(mediumish){
            width: calc(100% - 300px);
        }
    }

    a.back {
        position: absolute;
        top: -42px;
        left: 0;
        right: 0;

        margin: auto;

        font-size: 16px;
        font-weight: 700;
        color: white;

        @include mappy-bp(mediumish){
            top: 0px;
            right: auto;
        }

        img {
            display: inline-block;
            vertical-align: middle;
            width: 19px;
            margin: 0 5px 0 0;
        }
    }

    h1 {
        max-width: 600px;
        margin: 0 auto 30px;

        color: white;

        @include mappy-bp(mediumish){
            margin: 0 0 12px;

            font-size: 40px;
            line-height: 50px;
        }
    }

    p {
        font-size: 14px;
        color: rgba(white, 0.5);
    }
}
/********************************************/