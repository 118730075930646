/*********************************************
:: Sell CTA Module                    
********************************************/
.sell-cta-module {
    position: relative;

    padding: 60px 0;

    background: #1b1b1b;

    @include mappy-bp(mediumish){
        padding: 193px 0;
    }
    @include mappy-bp(largeish){
        padding: 143px 0;
    }

    .col {
        max-width: 670px;

        text-align: center;

        @include mappy-bp(mediumish){
            width: 47%;
            text-align: left;
        }
        @include mappy-bp(largeish){
            width: auto;
        }

        h3 {
            margin-bottom: 40px;

            font-size: 24px;
            line-height: 29px;
            letter-spacing: -0.3px;
            color: white;

            @include mappy-bp(largeish){
                font-size: 40px;
                line-height: 50px;
            }
        }
    }

    img {
        float: right;
        margin: 60px 0 0;

        @include mappy-bp(mediumish){
            position: absolute;
            top: 120px;
            right: 0;

            max-width: 45%;
            max-height: 500px;
            margin: 0;
        }
    }
}
/********************************************/

.sell-cta-module.right-text {
    .col {
        float: right;
    }

    img {
        float: left;

        @include mappy-bp(mediumish){
            right: auto;
            left: 0;
        }
    }
}
/********************************************/