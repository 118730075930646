/*********************************************
:: Home Page                   
********************************************/
.page-home {

    .hero-container {
        position: relative;
        width: 100%;
        min-height: 660px;

        text-align: center;

        @include bg(cover);

        @include mappy-bp(mediumish){ min-height: 880px; }

        .text {
            position: absolute;
            top: 163px;
            left: 0;
            right: 0;

            width: 88%;
            max-width: 780px;
            margin: auto;

            text-align: center;

            @include mappy-bp(smallish){
                top: 250px;
            }
            @include mappy-bp(mediumish){
                min-height: 613px;
                top: 352px;
            }

            h1, h3 {
                margin-bottom: 30px;

                color: white;
            }

            h3 {
                font-size: 18px;
                font-weight: $font-medium;
                line-height: 28px;
            }
        }

        a.down {
            position: absolute;
            bottom: 30px;
            left: 0;
            right: 0;
            margin: auto;

            span {
                font-size: 40px;
                font-weight: 300;
                color: white;
            }
        }
    }
    /********************************************/

    .about-container {
        padding: 60px 0;
        @include mappy-bp(mediumish){
            padding: 80px 0;
        }

        .col {
            text-align: center;

            @include mappy-bp(mediumish){
                width: 50%;
                padding: 0 90px 0 0;

                text-align: left;
            }
        }
        .col:last-of-type() {
            @include mappy-bp(mediumish){
                width: 50%;
                padding: 0 90px 0 15px;
            }

            a.button {
                margin: 0 20px 20px;
                @include mappy-bp(mediumish){ margin: 0 20px 0 0; }
            }
        }

    }
    /********************************************/
}
