/*********************************************
:: Layout
********************************************/

//
.titanic {
    float: none;
}

// Remove blue highlight on click
* {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

body {
    max-width: 100%;
    overflow-x: hidden;
}

/*******************************************/
// Main <section class="main" role="main">
.main {
    display: block;
    min-height: calc(100vh - #{$header-height-mobile + $footer-height-mobile});
    margin-top: -$header-height-mobile;

    @include mappy-bp(mediumish){
        min-height: calc(100vh - #{$header-height-desktop + $footer-height-desktop});
        margin-top: -$header-height-desktop;
    }
}

/*******************************************/
// Clear
.clear-after {
    &:after {
        content: '';
        display: block;
        clear: both;
    }
}


/*******************************************/
// Flex
.flex {
    display: flex;
    justify-content: center;
    flex-flow: wrap;

    &.space-around {
        justify-content: space-around;
    }
    &.flex-start {
        justify-content: flex-start;
    }
}

/*******************************************/
// Content Max Width
.content-max-width {
    position: relative;
    width: 100%;
    padding: 0 6%;

    @include mappy-bp(mediumish){
        max-width: 1170px;
        margin: 0 auto;
    }
    @include mappy-bp(large){ padding: 0; }
}

/*******************************************/
// Content Slider Width
.content-slider-width {
    position: relative;
    padding: 0;

    @include mappy-bp(mediumish){
        max-width: 940px;
        margin: 0 auto;
    }
    @include mappy-bp(large){
        max-width: 1170px;
    }
}

/*******************************************/
// Narrow Content Max Width
.content-narrow-width {
    position: relative;
    padding: 0;

    @include mappy-bp(mediumish){
        max-width: 740px;
        margin: 0 auto;
    }
    @include mappy-bp(large){
        max-width: 740px;
    }
}

/*******************************************/
// Breakpoints
.mob, .mobile {
    @include mappy-bp(mediumish){
        display: none !important;
    }
}

.desk, .desktop {
    @include mappy-bp(max-width mediumish){
        display: none !important;
    }
}

