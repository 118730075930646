/*********************************************
:: About Page                   
********************************************/
.page-about {

    .about-container {
        padding: 60px 0;

        text-align: center;

        @include mappy-bp(mediumish){
            padding: 80px 0 50px;
        }

        .content-max-width { max-width: 1200px; }

        article {
            width: 100%;
            max-width: 570px;
            margin: 0 auto 30px;
            
            @include mappy-bp(mediumish){
                width: calc(50% - 30px);
            }

            .image {
                position: relative;
                width: 100%;
                max-height: 400px;

                overflow: hidden;

                &:before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    z-index: 2;

                    width: 100%;
                    height: 130px;
                    
                    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
                }

                img { width: 100%; margin: 0; }
            }

            .content {
                position: relative;
                z-index: 9;

                width: calc(100% - 70px);
                margin: -65px 0 0;
                padding: 30px;

                text-align: left;

                background: white;

                .wysiwyg { text-align: left; }

                h3 {
                    margin-bottom: 6px;

                    font-size: 24px;
                    font-weight: $font-extrabold;
                    letter-spacing: -0.2px;
                    color: #2c2c2c;
                }

                h4 {
                    margin-bottom: 15px;
                    
                    font-size: 18px;
                    font-weight: $font-medium;
                    letter-spacing: -0.2px;
                    color: $color-1;
                }

                p {
                    margin-bottom: 25px;
                }
            }

            .contact {
                a {
                    display: block;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 24px;
                }
                a.phone {
                    color: #2c2c2c;
                }
                a.linkedin {
                    margin-top: 10px;
                }
            }
        }
    }
    /********************************************/
}
