/*********************************************
:: Contact Page                   
********************************************/
.page-contact {

    .contact-container {
        display: inline-block;
        width: 100%;
        padding: 40px 0 0;

        @include mappy-bp(mediumish) {
            padding: 80px 0 0;
        }

        .col1 {
            float: left;
            width: 100%;
            margin-bottom: 20px;

            @include mappy-bp(mediumish) {
                width: 50%;
                max-width: 370px;
                margin-bottom: 60px;
            }

            h3 {
                margin: 0 0 15px;

                font-size: 24px;
                font-weight: 700;
                line-height: 29px;
                color: $color-2;
            }

            a {
                display: inline-block;
                width: 100%;
                margin: 0 0 6px;

                font-family: $font-family-1;
                font-size: 18px;
                font-weight: 600;
                line-height: 24px;
                color: $color-1;
            }

            a.email {
                margin: 0 0 6px;
            }

            a.phone {
                margin: 0 0 15px;
                color: $color-2;
            }

            p {
                margin: 0 0 4px;
                font-size: 14px;
                line-height: 24px;

                @include mappy-bp(mediumish) {
                    margin: 0px 0 8px;
                }

                strong {
                    font-weight: 600;
                    color: $color-2;
                }

                &.location {
                    margin: 0 0 40px;
                }
            }

            .list { 
                p strong {
                    display: inline-block; 
                    width: 109px;
                }
            }

        }

        .col2 {
            float: left;
            width: 100%;
            padding: 20px 0;

            @include mappy-bp(mediumish) {
                float: right;
                width: 50%;
                max-width: 570px;
                padding: 0;
            }
        }

        .map-area {
            width: 100%;
            margin: 30px 0 0;

            .map {
                display: inline-block;
                width: 100%;
                height: 400px;
            }
        }
    }
    /********************************************/

    .jobs-container {
        width: 100%;
        margin: -4px 0 0;
        padding: 40px 6%;

        text-align: center;

        background: #1b1b1b;

        @include mappy-bp(mediumish) {
            padding: 80px 0 40px;
        }

        h3 , p {
            color: white;
        }

        h3 {
            margin-bottom: 10px;
            font-size: 40px;
            font-weight: 700;
            letter-spacing: -0.3px;
            line-height: 50px;
        }

        p {
            font-size: 18px;
            font-weight: 400;
            line-height: 28px;
        }
    }
    /********************************************/
}