/*********************************************
:: Top Content Module                    
********************************************/
.top-module {
	width: 100%;
    padding: 196px 0 35px;

    text-align: center;

    background: #1b1b1b;

    @include mappy-bp(mediumish){
        padding: 322px 0 105px;

        text-align: left;
    }

    .col {
        position: relative;
        width: 100%;
        margin: 0;

        @include mappy-bp(mediumish){
            width: 50%;
        }
    }

    .col:last-of-type {
        @include mappy-bp(mediumish){
            padding-left: 15px;
        }
    }

    p.bread {
        margin-bottom: 29px;

        font-size: 18px;
        font-weight: 600;
        color: $color-1;
    }

    a.back {
        position: absolute;
        top: -42px;
        left: 0;
        right: 0;

        margin: auto;

        font-size: 16px;
        font-weight: 600;
        color: white;

        @include mappy-bp(mediumish){
            top: -62px;
            right: auto;
        }

        img {
            display: inline-block;
            vertical-align: middle;
            width: 19px;
            margin: 0 8px 0 0;
        }
    }

    h1 {
        max-width: 470px;
        margin: 0 auto 30px;

        color: white;

        @include mappy-bp(mediumish){
            margin: 0 0 15px;

            font-size: 40px;
            line-height: 50px;
        }
    }

    h2.leadcopy {
        margin-bottom: 15px;

        font-size: 18px;
        font-weight: $font-semibold;
        line-height: 28px;
        letter-spacing: -0.3px;
        color: white;
    }

    h2,h3,h4,h5,h6,p {
        margin-bottom: 13px;
        color: white;
    }

    .button {
        margin: 25px 0 15px;
    }
}
/********************************************/