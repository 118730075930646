/*********************************************
:: Typography
********************************************/

html, body {
    font-family: $font-family-1;
    font-weight: $font-normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1,h2,h3,h4,h5,h6 {
    margin-bottom: 15px;

    font-family: $font-family-1 !important;
    font-weight: $font-bold;
    letter-spacing: -1.3px;
    color: #2c2c2c;

    &.title {
        margin-bottom: 40px;

        font-size: 32px;
        line-height: 40px;
        color: $color-1;

        @include mappy-bp(mediumish){
            margin-bottom: 60px;

            font-size: 40px;
            line-height: 50px;
        }
    }
}

h1 {
    font-size: 32px;
    line-height: 40px;

    @include mappy-bp(mediumish){
        font-size: 60px;
        line-height: 70px;
    }
}

h2 {
    font-size: 24px;
    line-height: 29px;

    @include mappy-bp(mediumish) {
        font-size: 26px;
    }
}

h3 {
    font-size: 24px;
    line-height: 29px;

    @include mappy-bp(mediumish) {
        font-size: 24px;
    }
}

h4 {
    font-size: 16px;
    line-height: 24px;

    @include mappy-bp(mediumish){
        font-size: 20px;
    }
}

h5 {
    font-size: 16px;
    line-height: 24px;

    @include mappy-bp(mediumish){
        font-size: 18px;
    }
}

h6 {
    font-size: 16px;
    line-height: 24px;

    @include mappy-bp(mediumish){
        font-size: 16px;
    }
}

/*******************************************/
//Main

p, li, td, th, mark, time, dl, label, address {
    margin-bottom: 40px;

    // font-size: 14px;
    font-size: 13.5px;
    font-weight: 500;
    line-height: 24px;
    color: #565656;

    a, li, p, mark, cite, time {
        font-size: 1em;
    }
}

a {
    margin: 0px;
    font-weight: $font-medium;
    text-decoration: none;
    color: $color-link;

    outline: none !important;
    transition: all .5s ease-in-out;

    &:hover, &:focus, &:active{
        color: $color-link-hover;
    }
}

small {
    font-size: 70%;
}

strong, b {
  font-weight: $font-bold;
}

em, i {
  font-style: italic;
}

hr {
    display: block;
    width: 100%;
    height: 1px;
    margin-bottom: 15px;

    background: $color-medium-grey;
    border: none;
    outline: none;
}


/*******************************************/
// Lists

dt {
    font-weight: $font-bold;

    &:after{
        content: ':';
    }
}

dt:after {
  content: ": ";
}


ul li{
    position: relative;
    list-style-type: none;
    text-indent: 0px;
    // &:before{
    //     content: "";
    //     width: 13px;
    //     height: 13px;
    //     background: $color-1;
    //     position: absolute;
    //     left: -24px;
    //     top: 6px;
    //     display: block;
    //     border-radius: 100%;
    // }
}

ol li {
    list-style-type: decimal;
}



/*******************************************/
// Code

code, pre, samp, kbd, var{
    margin: 0px;
    padding: 1px 5px 1px 5px;

    color: white;
    background: #2D747E;
    white-space: pre-wrap;
}

pre{
    padding: 10px;
}

/*******************************************/
// Blockquote

blockquote {
    margin-bottom: 40px;

    font-size: 18px;
    font-weight: 500;
    text-align: left;
    line-height: 28px;
    color: $color-1;
}

/*******************************************/
//Styles for Content Areas (wysiwyg)

.wysiwyg {
  text-align: center;
  @include mappy-bp(mediumish){
      text-align: left;
  }

    img {
        display: block;
        margin: 0 auto 15px auto;
    }

    ul {
        li {
            list-style-type: disc;
        }
    }

    img {
        margin-bottom: 40px;
    }
}

.leadcopy {
    margin-bottom: 26px;

    font-size: 15px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.3px;
    color: $color-2;

    @include mappy-bp(mediumish){
        font-size: 18px;
        line-height: 28px;
    }
}

/*******************************************/
//Typekit Trick

.wf-loading h1,
.wf-loading h2,
.wf-loading p,
.wf-loading a{
    visibility: hidden
}